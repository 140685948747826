import React from "react";
import Seo from "@components/common/Seo";
import BackgroundImage from "@components/common/CloudinaryImage";
import { Link } from "gatsby";
import "@styles/404.css";
import Logo from "@components/common/Logo";

const Error404 = () => {
    return (
        <>
            <Seo title="No se ha encontrado la página" />
            <div className="theme-light h-screen bg-black">
                <BackgroundImage className="h-full w-full" filename="bgs/bg404">
                    <div className="error404 flex h-full bg-opacity-75 bg-black">
                        <div className="mx-auto my-auto">
                            <div className="mx-10">
                                <Logo fadeIn={true} className="w-32 mx-auto" type="positive" />
                                <h1 className="h1">¡No se ha encontrado la página!</h1>
                                <div className="text-center mt-10">
                                    <Link to="/" className="button">
                                        Volver a la página inicial
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
        </>
    );
};
export default Error404;
